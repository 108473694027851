<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.317675 7.69505C0.317675 11.4331 3.34794 14.4633 7.08597 14.4633C8.64866 14.4633 10.0877 13.9337 11.2333 13.0442C11.2764 13.1355 11.3358 13.2211 11.4113 13.2966L16.8259 18.7112C17.1783 19.0636 17.7497 19.0636 18.1021 18.7112C18.4546 18.3588 18.4546 17.7874 18.1021 17.435L12.6875 12.0203C12.612 11.9448 12.5265 11.8855 12.4352 11.8424C13.3247 10.6967 13.8543 9.25775 13.8543 7.69505C13.8543 3.95703 10.824 0.926758 7.08597 0.926758C3.34794 0.926758 0.317675 3.95703 0.317675 7.69505ZM2.12255 7.69505C2.12255 4.95383 4.34475 2.73164 7.08597 2.73164C9.82719 2.73164 12.0494 4.95383 12.0494 7.69505C12.0494 10.4363 9.82719 12.6585 7.08597 12.6585C4.34475 12.6585 2.12255 10.4363 2.12255 7.69505Z"
      fill="currentColor"
    />
  </svg>
</template>
